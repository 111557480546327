import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { dayjs } from '@futures.tw/dayjs';
export function createEventLogStore() {
    const initialFilterValues = {
        account: '',
        eventName: '',
        eventLevel: '',
        eventDateGte: dayjs().subtract(3, 'months'),
        eventDateLte: dayjs(),
        sortBy: FrLogic.sortBy(['datetime']),
        keyword: '',
    };
    const store = proxy({
        //
        // state
        data: [],
        pagination: FrLogic.pagination(),
        filter: { ...initialFilterValues },
        //
        // actions
        resetFilter() {
            store.filter = { ...initialFilterValues };
        },
        patchFilter(values) {
            store.filter = {
                ...store.filter,
                ...values,
            };
        },
        async loadData() {
            const res = await apirc.strategy.http('get', '/api/eventLogs').request({
                account: store.filter.account,
                eventName: store.filter.eventName === '' ? undefined : store.filter.eventName,
                eventLevel: store.filter.eventLevel === '' ? undefined : store.filter.eventLevel,
                'datetime[gte]': store.filter.eventDateGte.startOf('day').format(),
                'datetime[lte]': store.filter.eventDateLte.endOf('day').format(),
                keyword: store.filter.keyword,
                ...store.filter.sortBy.httpParams,
                ...store.pagination.getHttpParams(),
            });
            store.data = res.data || [];
            store.pagination.setPagination(res);
        },
    });
    return store;
}
